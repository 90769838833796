:root {
  --1-16: 16px;
  --1-24: 24px;
  --1-32: 32px;
  --1-40: 40px;
  --1-48: 48px;
  --1-56: 56px;
  --1-64: 64px;
  --1-8: 8px;
  --background: rgba(255, 255, 255, 1);
  --border-gray: rgba(238, 238, 238, 1);
  --component-button-text-font-family: "Noto Sans JP", Helvetica;
  --component-button-text-font-size: 13px;
  --component-button-text-font-style: normal;
  --component-button-text-font-weight: 500;
  --component-button-text-letter-spacing: 0px;
  --component-button-text-line-height: normal;
  --component-tag-text-font-family: "Noto Sans JP", Helvetica;
  --component-tag-text-font-size: 9px;
  --component-tag-text-font-style: normal;
  --component-tag-text-font-weight: 700;
  --component-tag-text-letter-spacing: 0px;
  --component-tag-text-line-height: 100%;
  --disabled: rgba(217, 217, 217, 1);
  --elevation-2: 4px 4px 4px 0px rgba(56, 69, 77, 0.15);
  --error: rgba(202, 0, 0, 1);
  --form-caption-font-family: "Inter", Helvetica;
  --form-caption-font-size: 10px;
  --form-caption-font-style: normal;
  --form-caption-font-weight: 400;
  --form-caption-letter-spacing: 0px;
  --form-caption-line-height: normal;
  --form-item-font-family: "Inter", Helvetica;
  --form-item-font-size: 13px;
  --form-item-font-style: normal;
  --form-item-font-weight: 700;
  --form-item-letter-spacing: 0px;
  --form-item-line-height: normal;
  --form-label-font-family: "Inter", Helvetica;
  --form-label-font-size: 11px;
  --form-label-font-style: normal;
  --form-label-font-weight: 700;
  --form-label-letter-spacing: 0px;
  --form-label-line-height: normal;
  --glj7-6w: rgba(29, 163, 251, 1);
  --in-page-body-font-family: "Noto Sans JP", Helvetica;
  --in-page-body-font-size: 11px;
  --in-page-body-font-style: normal;
  --in-page-body-font-weight: 300;
  --in-page-body-letter-spacing: 0px;
  --in-page-body-line-height: normal;
  --in-page-body-strong-font-family: "Noto Sans JP", Helvetica;
  --in-page-body-strong-font-size: 11px;
  --in-page-body-strong-font-style: normal;
  --in-page-body-strong-font-weight: 700;
  --in-page-body-strong-letter-spacing: 0px;
  --in-page-body-strong-line-height: normal;
  --in-page-head-font-family: "Noto Sans JP", Helvetica;
  --in-page-head-font-size: 12px;
  --in-page-head-font-style: normal;
  --in-page-head-font-weight: 700;
  --in-page-head-letter-spacing: 0px;
  --in-page-head-line-height: normal;
  --in-page-title-font-family: "Noto Sans JP", Helvetica;
  --in-page-title-font-size: 18px;
  --in-page-title-font-style: normal;
  --in-page-title-font-weight: 700;
  --in-page-title-letter-spacing: 0px;
  --in-page-title-line-height: normal;
  --list-item-font-family: "Noto Sans JP", Helvetica;
  --list-item-font-size: 11px;
  --list-item-font-style: normal;
  --list-item-font-weight: 300;
  --list-item-letter-spacing: 0px;
  --list-item-line-height: 100%;
  --MA-ah-6n: rgba(0, 155, 243, 1);
  --menu-text-font-family: "Noto Sans JP", Helvetica;
  --menu-text-font-size: 7px;
  --menu-text-font-style: normal;
  --menu-text-font-weight: 400;
  --menu-text-letter-spacing: 0px;
  --menu-text-line-height: 100%;
  --mfa-1f9: rgba(78, 78, 78, 1);
  --p-jrz-gm: rgba(246, 246, 246, 1);
  --page-description-font-family: "Inter", Helvetica;
  --page-description-font-size: 13px;
  --page-description-font-style: normal;
  --page-description-font-weight: 300;
  --page-description-letter-spacing: 0px;
  --page-description-line-height: 129.99999523162842%;
  --page-title-font-family: "Inter", Helvetica;
  --page-title-font-size: 28px;
  --page-title-font-style: normal;
  --page-title-font-weight: 700;
  --page-title-letter-spacing: 0px;
  --page-title-line-height: normal;
  --PH-wj-AW: rgba(255, 255, 255, 1);
  --placeholder: rgba(238, 238, 238, 1);
  --primary: rgba(0, 155, 243, 1);
  --small-text-font-family: "Noto Sans JP", Helvetica;
  --small-text-font-size: 10px;
  --small-text-font-style: normal;
  --small-text-font-weight: 300;
  --small-text-letter-spacing: 0px;
  --small-text-line-height: normal;
  --SP-bold-text-s-16-160-4-bold-font-family: "Noto Sans JP", Helvetica;
  --SP-bold-text-s-16-160-4-bold-font-size: 16px;
  --SP-bold-text-s-16-160-4-bold-font-style: normal;
  --SP-bold-text-s-16-160-4-bold-font-weight: 700;
  --SP-bold-text-s-16-160-4-bold-letter-spacing: 0.64px;
  --SP-bold-text-s-16-160-4-bold-line-height: 160.0000023841858%;
  --SP-medium-text-xs-14-160-8-medium-font-family: "Noto Sans JP", Helvetica;
  --SP-medium-text-xs-14-160-8-medium-font-size: 14px;
  --SP-medium-text-xs-14-160-8-medium-font-style: normal;
  --SP-medium-text-xs-14-160-8-medium-font-weight: 500;
  --SP-medium-text-xs-14-160-8-medium-letter-spacing: 1.12px;
  --SP-medium-text-xs-14-160-8-medium-line-height: 160.0000023841858%;
  --text-main-color: rgba(56, 56, 56, 1);
  --text-sub-color: rgba(148, 148, 148, 1);
  --u-xe-hpg: rgba(29, 29, 29, 1);
  --variationgreen: rgba(164, 226, 194, 1);
  --variationpink: rgba(244, 178, 180, 1);
  --variationpurple: rgba(213, 187, 233, 1);
  --variationyellow: rgba(253, 222, 132, 1);
}
